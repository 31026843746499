import React from "react";
import {Button, Card, CardContent, CardHeader, Container, Form, Message, Image} from "semantic-ui-react";
import {UsernameEmailField} from "../form-fields";
import {Link} from "gatsby";
import withTrans from "../../../i18n/withTrans";
import cookie from "react-cookies";
import email from "../../../images/login/email.png";
import pwdbox from "../../../images/login/pwdbox.png";
import pwdhint from "../../../images/login/pwdhint.png";
import sendemail from "../../../images/login/sendemail.png";
import modifypwd from "../../../images/login/modifypwd.png";

function ResetPasswordFormView(props) {
    const {
        loading,
        success,
        errorMsgs,
        onUsernameChange,
        onSubmit,
        back,
        t
    } = props;
    return (
        <Container fluid>
            <div style={{
                    padding:"20px",
                    backgroundImage: `url(${pwdbox})` , backgroundSize:'100% 100%'
                }}>

                <Card fluid style={{
                    boxShadow: '0 1px 1px 0 #FFF, 0 0 0 1px #FFF'
                }}><Form size={"small"} loading={loading} success={success}
                error={errorMsgs.length > 0}>
                    <CardContent>
                    {(errorMsgs.length > 0) && (<div style={{color:"red", paddingTop: "10px"}}>{props.t("COMMON.ERROR")}:{errorMsgs.join("\n")}</div>)}                    
                    {(success) && (<div style={{color:"#2EB414", paddingTop: "10px"}}>郵箱已綁定</div>)}                    
                        <div style={{padding:"10px",height:"4rem",
                    border: "0px",
                        backgroundImage: `url(${email})` , backgroundSize:'100% 100%'
                        }}>
                        <UsernameEmailField 
                        style={{width:"80%", height: "90%" ,
                        marginTop: "-3px",
                        marginLeft: "10%",
                        backgroundColor: "#FFF", color:"#333"}}  
                        onChange={onUsernameChange}/>
                        </div>

                        <Image style={{
                        marginTop: "4px",
                        marginBottom: "2vh",
                        width: "100%"
                    }} centered src={pwdhint}/>
                        {(!success) && (<Button
                            style={{
                                height:"4.2rem",
                                background: "transparent",
                                backgroundImage: `url(${!success ? sendemail: modifypwd})` , backgroundSize:'100% 100%'}}     
                            // disabled={success}  
                            fluid size='large'
                                onClick={onSubmit}>
                            
                        </Button>)}
                        
                    </CardContent>
                    </Form>
                </Card>
                

            
                </div>
            
        </Container>
    );
}

export default withTrans(ResetPasswordFormView);

// <Form size='large' loading={this.state.loading} success={this.state.success}
//       error={this.state.errorMsgs.length > 0}>
//     <Segment stacked>
//         <UsernameField onChange={(e, data) => this.state.username = data.value}/>
//         <Message
//             error
//             header='Error'
//             content={this.state.errorMsgs.join("\n")}
//         />
//         <Button disabled={this.state.success} color='orange' fluid size='large' onClick={this.onSubmit.bind(this)}>
//             {this.state.success ? "驗證郵件已發出，請檢查你的郵件箱" : "重設密碼"}
//         </Button>
//     </Segment>
//     <Message>
//         已有帳號? <Link to='/login'>登入</Link>
//     </Message>
//     <Message>
//         <Link to='/'>回到主頁</Link>
//     </Message>
// </Form>