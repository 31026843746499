import React, {Component} from "react";
import KaisakuApi from "../../../controllers/kaisaku-api";
import View from "./reset-password-form-view";
import withTrans from "../../../i18n/withTrans";
import {formatQuery} from "../../../controllers/utilities/string-utils";

class ResetPasswordForm extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            username: "",
            errorMsgs: [],
            loading: false,
            success: false
        };
    }

    onUsernameChange(e, data) {
        this.setState({username: data.value});
    }

    async onSubmit() {
        this.setState({
            loading: true,
            success: false,
            errorMsgs: []
        });
        console.log(this.props);
        const res = await KaisakuApi.resetPassword(this.state.username);
        this.processResult(res);
    }

    processResult(result) {
        if(result.success) {
            this.setState({
                loading: false,
                success: true
            });
        } else {
            this.setState({
                loading: false,
                success: result.success,
                errorMsgs: result.errorMsgs.map(this.props.t)
            });
        }
    }

    render() {
        return (
            <View
                loading={this.state.loading}
                success={this.state.success}
                errorMsgs={this.state.errorMsgs}
                onUsernameChange={this.onUsernameChange.bind(this)}
                onSubmit={this.onSubmit.bind(this)}
                back={this.props.back}
            />
        );
    }

}

export default withTrans(ResetPasswordForm);